<template>
    <div class="fix-full">
        <div class="absolute-full">
            <div class="mask" @click="closeWindow"></div>
            <div class="window-container">
                <p class="title">{{remarkData.name}}</p>
                <p class="remark" v-html="remarkData.remark"></p>
                <div class="btn-box"
                     @click="closeWindow">
                    <button class="close-btn">我知道了</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name:"remark-window",
        props:{
			remarkData:{
				type:Object,
                default:()=>{}
            },
        },
        methods:{
			closeWindow(){
				this.$emit('closeWindow')
			}
        }
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .fix-full{
    @extend %fix-full;
        z-index:2;
    }

    .absolute-full{
    @extend %full;
    @extend %flex-center-row;
    }

    .mask{
    @extend %mask;
    }

    .window-container{
        position:absolute;
        left:75px;
        right:75px;
        z-index:1;
        background:#FFFFFF;
        border-radius:10px;
        padding:0px 30px 50px;
    }
    .title{
        text-align:center;
        font-size:32px;
        color:#000;
        font-weight:bold;
        padding:56px 0 37px;
        border-bottom: rgb(229,229,229) 1px solid;
    }
    .remark{
        text-align:center;
        font-size:30px;
        padding:42px 0;
        color:rgb(102,102,102);
    }
    .btn-box{

        .close-btn{
            display:inline-block;
            background:$high-light-color;
            color:#FFFFFF;
            width:100%;
            height:75px;
            line-height:75px;
            font-size:28px;
            text-align:center;
            border-radius:10px;
            font-weight:bold;
        }
    }
</style>
